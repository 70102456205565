<template>
    <div class="row archivo mx-0 f-12 border-bottom">
        <div class="col-md-3 col-lg-4 my-auto pl-0">
            <div class="d-middle cr-pointer" :id="{'df_gbp': fileType == 1}" :class="{'_df_custom': fileType == 1}" @click="$emit('viewFile')">
                <i class="f-23" :class="fileTypes[fileType] || fileTypes.default" />
                <p class="f-600 tres-puntos">{{ fileName }}</p>
            </div>
        </div>
        <div class="col-md-3 col-lg-3 my-auto">
            <div class="d-middle">
                <img :src="url | helper-storage" alt="" class="wh-26 rounded-circle">
                <p class="ml-1 tres-puntos">{{ responsible || '-' }}</p>
            </div>
        </div>
        <div class="col-md-4 col-lg-3 text-center my-auto">
            <p class="py-3 f-500">{{ createdAt | helper-fecha('DD MMM. YYYY')}}</p>
        </div>
        <div class="col-md-2 col-lg-2 my-auto text-center icon-option" >
            <ValidationObserver ref="validator">
                <el-popover 
                    v-model="visibleEditarArchivo" 
                    placement="bottom-end" 
                    width="360" 
                    trigger="click" 
                    class="px-0"  
                    :disabled="!permitAction(permisoEditar)"
                >
                    <div class="row mx-0 justify-content-center f-600 text-general">
                        Editar archivo
                    </div>
                    <hr />
                    <div class="row mx-0 justify-content-center my-2">
                        <div class="col-10 my-2">
                            <ValidationProvider name="nombre del archivo" rules="required" v-slot="{errors}">
                                <p class="input-label-top">Nombre del archivo</p>
                                <el-input v-model="model.nombre" class="f-12 br-5 w-100" size="small" />
                                <vee-error :text="errors[0]"/>
                            </ValidationProvider>
                        </div>
                    </div>
                    <hr />
                    <div class="text-right m-0">
                        <button class="btn btn-cerrar f-12" @click="visibleEditarArchivo = !visibleEditarArchivo">Cerrar</button>
                        <button class="btn btn-crear f-12 ml-2" >Guardar</button>
                    </div>
                    <i 
                        slot="reference" 
                        class="icon-pencil-outline f-20" 
                        :class="permitAction(permisoEditar) ? 'cr-pointer' : 'cr-not-allowed'"
                        @click="functionPermitAction(permisoEditar, openEditFile)"
                    />
                </el-popover>
            </ValidationObserver>
            <i class="icon-trash-can-outline f-20" 
                :class="permitAction(permisoEliminar) ? 'cr-pointer' : 'cr-not-allowed'"
                @click="functionPermitAction(permisoEliminar, () => {$emit('removeFile')})"

            />
        </div>
    </div>
</template>

<script>
const model = {
    nombre: ''
}
export default {
    props:{
        id: {
            type: Number,
            required: true
        },
        url: {
            type: String,
        },
        fileName: {
            type: String,
        },
        fileType:{
            type: Number,
        },
        responsible: {
            type: String,
        },
        createdAt: {
            type: String
        },
        callbackUpdate: {
            type: Function,
            default : () => {}
        },
        permisoEditar: {
            type: String,
            required: false
        },
        permisoEliminar: {
            type: String,
            required: false
        },
    },
    methods: {
        reset(){
            this.model = {...model}

        }
    },
    data(){
        return {
            model: {...model},
            fileTypes: {
                1: 'icon-pdf text-pdf',
                2: 'icon-image text-imagen',
                3: 'icon-movie text-info2',
                4: 'icon-file',
                default: 'icon-file'
            },
            visibleEditarArchivo: false,
        }
    },
    methods: {
        openEditFile(){
            this.model.nombre = this.fileName
        },
        async updateFileName(){
            const valid = await this.$refs.validator.validate()
            if(!valid) return

            await this.callbackUpdate({ id_file: this.id, nombre: this.model.nombre })
            this.visibleEditarArchivo = !this.visibleEditarArchivo
        }
    }
}
</script>