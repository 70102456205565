<template>
    <div class="listado-archivos px-2 pb-3 overflow-auto custom-scroll mr-1 mb-2 ">
        <template v-for="(file,idx) in files">
            <archivo 
            :key="idx" 
            :id="file.id"
            :url="file.foto"
            :fileName="file.nombre"
            :fileType="file.id_tipo"
            :createdAt="file.fecha_creacion"
            :responsible="file.responsable"
            :callbackUpdate="Action_update_file_name"
            :permisoEditar="'taller.carpetas.editar.archivo'"
            :permisoEliminar="'taller.carpetas.eliminar.archivo'"
            @viewFile="viewFile(file)"
            @removeFile="removeFile(file.id)"
            />
        </template>
        <modal-media-visualizer ref="modalViewFiles" :media="file" :extension="fileType" />
        <modal-eliminar ref="modalDeleteFile" title="Eliminar archivo" mensaje="¿Está seguro que quiere eliminar este archivo?" @delete="deleteFile"/>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Archivo from './components/archivo.vue'
export default {
    components: { Archivo },
    data(){
        return {
            fileTypes: {
                1: 'icon-pdf text-pdf',
                2: 'icon-image text-imagen',
                3: 'icon-movie text-info2',
                4: 'icon-file',
                default: 'icon-file'
            },
            file: null,
            fileToDelete: null,
        }
    },
    computed:{
        ...mapGetters({
            files: 'talleres/ver_documentos/files'
        }),
        fileType(){
            return this.file?.split('.').pop()
        },
    },
    methods: { 
        ...mapActions({
            Action_get_files: 'talleres/ver_documentos/Action_get_files',
            Action_update_file_name: 'talleres/ver_documentos/Action_update_file_name',
            Action_remove_file: 'talleres/ver_documentos/Action_remove_file',
        }),
        viewFile(file){
            if (file.id_tipo == 1) {
                this.set_pdf(file.foto)
            } else if(([2,3]).includes(file.id_tipo)){
                this.file = file.foto
                this.$refs.modalViewFiles.toggle()
            } else {
                this.$notify({
                    title: 'No se puede visualizar el archivo',
                    type:'warning',
                    duration: 2000,
                })
            }
        },
        removeFile(id){
            this.fileToDelete = id
            this.$refs.modalDeleteFile.toggle()
        },
        async deleteFile(){
            await this.Action_remove_file(this.fileToDelete)
            this.fileToDelete = null
        }
    }
}
</script>